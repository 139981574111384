import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last day of Deload Week`}</em></p>
    <p>{`DB Deadlifts 3×12 (both heads of DB’s touch the ground if possible)`}</p>
    <p>{`Plank 3×1:30`}</p>
    <p>{`then,`}</p>
    <p>{`5 rounds for time:`}</p>
    <p>{`20-KB SDHP’s (53/35)(RX+ 70/53)`}</p>
    <p>{`Run 1/2 block to turf`}</p>
    <p>{`70ft Burpee Broad Jump`}</p>
    <p>{`Run 1/2 block to front doors`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      